
@font-face {
  font-family: Sequel100Black-75;
  src: url(./fonts/sequel/sequel-100-black-75.ttf);
}

@font-face {
  font-family: Inter;
  src: url(./fonts/Inter/Inter-Medium.ttf);
}

@font-face {
  font-family: BebasNeue;
  src: url(./fonts/Bebas/BebasNeue-Regular.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(./fonts/Montserrat/Montserrat-Bold.ttf);
}
@font-face {
  /*font-family: Rubik;*/
  /*src: url(./fonts/Montserrat/Montserrat-Bold.ttf);*/
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  /*background: #000;*/
  height: 100%;
}


.App{
  font-family: BebasNeue;
}


::-webkit-scrollbar{
  width: 3px;
  height : 3px;
}
::-webkit-scrollbar-thumb{
  background-color: rgba(255, 79, 79, 1);
  border-radius: 30px;
}

.ant-table-cell:hover{
  background: transparent!important;
}


/* modal  */
.myModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #ccc;
  background: transparent;
  overflow: auto;
  outline: none;
  border-radius: 15px;
}

.myOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}


.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}


/*!*antd table*!*/
/*!*table style*!*/
/* .ant-table {*/

/*}*/

/* .ant-table {*/
/*  border-radius: 0 !important;*/
/*  !*height: 100%!important;*!*/
/*}*/

/* .ant-table-content {*/

/*}*/

/* .ant-table-cell {*/
/*  !*background: blue!important;*!*/
/*  border-radius: 0 !important;*/
/*  font-family: Inter;*/
/*  font-size: 16px !important;*/
/*  font-weight: 700 !important;*/
/*  line-height: 19.36px !important;*/
/*  color: #FFFFFF !important;*/
/*  text-align: center !important;*/
/*}*/

/* .ant-table {*/
/*  background: transparent !important;*/
/*}*/

/* .ant-table-thead .ant-table-cell {*/
/*  border-bottom: 4px solid rgba(255, 79, 79, 0.2) !important;*/
/*  background: transparent !important;*/
/*  !*position: sticky;*!*/
/*  !*top : 0;*!*/
/*  z-index: 100;*/
/*}*/

/* .ant-table-tbody {*/
/*  !*background: green!important;*!*/
/*  !*    height:100%!important;*!*/
/*  !*    overflow-y: auto!important;*!*/
/*}*/

/* .ant-empty-description {*/
/*  display: none;*/
/*}*/

/* .ant-table-tbody .ant-table-cell {*/
/*  border: 0 !important;*/
/*}*/

/* .ant-table-tbody .ant-table-cell-row-hover {*/
/*  background: rgba(255, 79, 79, 0.2) !important;*/
/*}*/

/* .ant-table-placeholder {*/
/*  background: transparent !important;*/
/*}*/

/* .ant-table-placeholder:hover {*/
/*  background: transparent !important;*/
/*}*/

/* .ant-table-placeholder:hover {*/
/*  background: rgba(255, 79, 79, 0.2) !important;*/
/*}*/

/* .ant-table-thead tr .ant-table-cell {*/
/*  height: 20px !important;*/
/*  padding: 10px 10px !important;*/
/*}*/
/*!*antd table*!*/