.login {
    height: 100vh;
    padding: 20px 40px;
    box-sizing: border-box;
    background: black;
}

.login_inner {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-gap: 140px;
    width: 100%;
    height: 100%;
}

.login_inner_left {
    padding: 0 130px;
    position : relative;
}
.login_inner_left_back{
    position : absolute;
    top : 0;
    left : 0;
}
.login_inner_left_back svg{
    font-size : 30px;
    color: rgba(255, 79, 79, 1);
    cursor : pointer;
}

.login_inner_left_menu {
    height: 50px;
    background: linear-gradient(0deg, #000000, #000000),linear-gradient(0deg, #C1C1C1, #C1C1C1);
    border: 0.5px solid rgba(193, 193, 193, 1);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    padding: 5px 10px;
    align-items: center;
}

.login_inner_left_menu_inner {
    font-family: Sequel100Black-75;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.login_inner_left_menu_inner a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #FFFFFF;
}

.active {
    background: rgba(23, 23, 23, 1);
    border-radius: 7px;
    color: rgba(255, 79, 79, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 10px;
}

.active a {
    color: rgba(255, 79, 79, 1);
}

.login_inner_left_form {
    height: calc(100% - 50px);
    padding-top: 60px;
    display: flex;
    align-items: center;
}

.login_inner_left_form_inner {
    width: 100%;
}

.login_inner_left_form_field {
    margin-top: 30px;
}

.login_inner_left_form_field:first-child {
    margin: 0;
}

.login_inner_left_form_field .ant-input {
    height: 45px;
    border-radius: 10px;
    background: linear-gradient(0deg, #171717, #171717),
    linear-gradient(0deg, #C1C1C1, #C1C1C1);
    border: 0.5px solid rgba(193, 193, 193, 1);
    color: #FFFFFF;
    font-family: Sequel100Black-75;
    font-size: 14px !important;
    font-weight: 400;
    /*line-height: 24px;*/
}

.login_inner_left_form_field .ant-input-password {
    height: 45px;
    background-color: black;
}

.login_inner_left_form_field .ant-input-password .ant-input {
    height: 35px;
}

.ant-input-suffix svg {
    color: aliceblue;
}
.login_inner_left_form_field .ant-input-affix-wrapper{
    background-color: #171717!important;
}
.login_inner_left_form_field .ant-input-affix-wrapper:hover{
    border: 0.5px solid rgba(193, 193, 193, 1) !important;
}
.login_inner_left_form_field .ant-input::placeholder {
    color: #FFFFFF;
    font-family: Sequel100Black-75;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.login_inner_left_form_field .ant-form-item-explain-error {
    font-family: Sequel100Black-75;
    font-size: 12px;
}

.login_inner_left_form_button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.login_inner_left_form_button button {
    background: rgba(23, 23, 23, 1);
    border-radius: 10px;
    border: 0;
    height: 45px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    /*width: 140px;*/
    font-family: Sequel100Black-75;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(255, 79, 79, 1);
    cursor: pointer;
}

.login_inner_left_form_button button:hover {
    color: rgba(23, 23, 23, 1);
    background: rgba(255, 79, 79, 1);
}

.login_inner_left_form p {
    font-family: Sequel100Black-75;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 20px;
    cursor: pointer;
}

.login_inner_right {
    /*padding: 0 20px;*/
}

.login_inner_right_inner {
    background: linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, #C1C1C1, #C1C1C1);
    border: 0.5px solid rgba(193, 193, 193, 1);
    text-align: center;
    border-radius: 10px;
    padding: 10px 40px;
    box-sizing : border-box;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    /*background: green;*/
}

.login_inner_right_inner_text {
    /*overflow-y: auto;*/
    height : 50%;
}

.login_inner_right h1 {
    font-family: Sequel100Black-75;
    font-size: 20px;
    font-weight: 400;
    color: rgba(255, 79, 79, 1);
    text-align: left;
    margin-top: 5px;
}

.login_inner_right h2 {
    font-family: Sequel100Black-75;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 79, 79, 1);
}

.login_inner_right p {
    font-family: Inter;
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px;
}

.login_inner_right p:last-child {
    font-style: italic;
}

.login_inner_right_video {
    background: rgba(23, 23, 23, 1);
    border-radius: 10px;
    width: 100%;
    height: 200px;
    margin: 0 auto;
    margin-top: 10px;
}

.login_inner_left_form_field input::-webkit-inner-spin-button {
    display: none;
}
@media(max-width: 1600px) {
    .login_inner_right h1 {
        font-family: Sequel100Black-75;
        font-size: 17px;
        font-weight: 400;
        line-height: 30px;
        color: rgba(255, 79, 79, 1);
        text-align: left;
        margin-top: 10px;
    }

    .login_inner_right h2 {
        font-family: Sequel100Black-75;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(255, 79, 79, 1);
    }

    .login_inner_right p {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        /*line-height: 15px;*/
        text-align: left;
        color: #FFFFFF;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 1300px) {
    .login_inner_left {
        padding: 0 130px;
    }
}

@media only screen and (min-width: 1498px) {
    .login_inner_left {
        padding: 0 150px;
    }
    .login {
        padding: 40px 50px;
    }

    .login_inner_right_inner {
        padding: 20px 40px;
        width: 85%;
    }

    .login_inner_right_video {
        height: 200px;
    }
}

@media only screen and (min-width: 1686px) {
    .login_inner_left {
        padding: 0 200px;
    }

    .login {
        padding: 50px 50px;
    }

    .login_inner_right_inner {
        padding: 20px 40px;
        width: 80%;
    }

    .login_inner_right_video {
        height: 250px;
    }
}

@media only screen and (min-width: 1800px) {
    .login_inner_left {
        padding: 0 250px;
    }

    .login {
        padding: 50px 50px;
    }

    .login_inner_right_inner {
        padding: 20px 40px;
        width: 75%;
    }

    .login_inner_right_video {
        height: 270px;
    }
}

@media only screen and (min-width: 2000px) {
    .login_inner_left {
        padding: 0 300px;
    }

    .login {
        padding: 60px 50px;
    }

    .login_inner_right_inner {
        padding: 20px 40px;
        width: 75%;
    }

    .login_inner_right_video {
        height: 290px;
    }
}

@media (max-width: 576px) {
    .login {
        height: 100%;
        padding: 15px 40px;
    }
    .login_inner {
        display: inline-block;
    }
    .login_inner_left {
        padding: 0;
    }
    .login_inner_left_back{
        position : absolute;
        top : 0;
        left : -30px;
    }
    .login_inner_left_back img{
        width : 25px;
        height:  25px;
    }
    .login_inner_left_back svg{
        font-size : 25px;
    }
    .login_inner_left_menu {
        height: 45px;
    }
    .login_inner_left_menu_inner a {
        font-size: 10px;
        line-height: 10px;
    }
    .login_inner_left_form_field .ant-input {
        height: 38px;
        font-size: 12px !important;
    }

    .login_inner_left_form_field .ant-input-password {
        height: 38px;
    }

    .login_inner_left_form_field .ant-input-password .ant-input {
        height: 30px;
    }

    .login_inner_left_form_field .ant-input::placeholder {
        font-size: 12px;
    }

    .login_inner_left_form_button button {
        height: 38px;
        padding: 15px 30px;
        font-size: 12px;
        margin-top: 10px;
    }

    .login_inner_right_inner {
        border: 0;
        padding: 10px 0px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }

    .login_inner_right_inner h2 {
        font-size: 12px;
    }

    .login_inner_right_inner_text h1 {
        text-align: center;
        font-size: 16px;
    }

    .login_inner_right_inner_text p {
        font-size: 12px;
    }

}

