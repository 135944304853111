.all_courses {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.all_courses_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.all_courses_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.all_courses_body {
    height: calc(100vh - 145px);
    /*overflow-y: auto;*/
}

.all_courses_body_carousel {

}

.all_courses_body_price {
    display: flex;
    align-items: center;
    margin: 15px 0;
}

.all_courses_body_price p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    color: white;
}

.all_courses_body_price button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    border-radius: 40px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 12.1px;
    text-align: left;
    color: white;
    border: 0;
    margin-left: 30px;
    padding: 12px 15px;
    cursor: pointer;
}

.all_courses_body_cards {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-top: 30px;
}


/*carousel*/
.all_courses_carousel {
    border: 2px solid rgba(255, 79, 79, 0.2);
    /*width: 100%;*/
    /*height: 280px;*/
    border-radius: 25px;
    margin: 0 5px;
    cursor: pointer;
}

.all_courses_carousel_head {
    height: 180px;
}
.all_courses_carousel_head img{
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
}

.all_courses_carousel_line {
    border: 1px solid rgba(192, 183, 232, 0.33);
    width: 30%;
    margin: 10px auto;
}
.all_courses_carousel_body{
    height: 120px;
    overflow-y: auto;
    padding : 0px 10px;
    margin : 10px 0;
    box-sizing: border-box;
}
.all_courses_carousel_body p{
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: white;
}

@media (max-width: 1600px) {
    .all_courses_carousel_head {
        height: 130px;
    }
    .all_courses_carousel_body{
        height: 90px;
    }
    .all_courses_carousel_body p{
        font-size: 12px;
    }
    .all_courses_body_price p {
        font-size: 16px;
    }
    .all_courses_body_price button {
        font-size: 12px;
        padding: 8px 15px;
    }
}

.all_courses_carousel_card {
    margin: 0 10px;
    border: 2px solid rgba(255, 79, 79, 0.2);
    border-radius: 25px;
    display : grid;
    grid-template-rows: 1fr 0.01fr 0.5fr 0.4fr;
    /*grid-gap : 0;*/
}
.all_courses_carousel_card_head {
    display: flex;
    justify-content: center;
    height: 180px;
    border-radius: 25px;
}
.all_courses_carousel_card_head img {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: cover;
}

.all_courses_carousel_card_line {
    border: 1px solid rgba(192, 183, 232, 0.33);
    width: 40%;
    margin: 10px auto;
}
.all_courses_carousel_card_body {
    padding: 0px 10px;
    box-sizing: border-box;
    height : 110px;
    overflow-y : auto;
}
.all_courses_carousel_card_body p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
}

.all_courses_carousel_card_footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0px 0;
}

.all_courses_carousel_card_footer_left {
    border: 1px solid white;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 9.68px;
    text-align: center;
    color: white;
    padding: 15px 5px;
    border-radius: 40px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    cursor : pointer;
}

.all_courses_carousel_card_footer_right {
    border: 0px solid white;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 9.68px;
    text-align: center;
    color: white;
    padding: 15px 5px;
    border-radius: 40px;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    cursor : pointer;
}
@media (max-width: 1600px) {
    .all_courses_carousel_card_head {
        height: 130px;
    }
    .all_courses_carousel_card_body{
        height: 80px;
    }
    .all_courses_carousel_card_body p{
        font-size: 12px;
    }
    .all_courses_carousel_card_footer_left{
        font-size: 10px;
        width: 80px;
        padding: 10px 5px;
    }
    .all_courses_carousel_card_footer_right{
        font-size: 10px;
        width: 80px;
        padding: 10px 5px;
    }
}
.p-carousel-prev svg {
    color: rgba(255, 79, 79, 1);
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.p-carousel-next svg {
    color: rgba(255, 79, 79, 1);
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

/*carousel*/

/*mobile*/
@media (max-width: 576px) {
    .all_courses_header {
        height: 40px;
    }

    .all_courses_header h2 {
        font-size: 14px;
    }

    .all_courses_body_price p {
        font-size: 12px;
    }

    .all_courses_body_price button {
        font-size: 10px;
        padding: 5px 15px;
    }

    .all_courses_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }

    .all_courses_body_cards {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        margin-top: 30px;
    }
    .all_courses_body_cards .all_courses_carousel_card {
        margin: 0 0px;
    }
    .all_courses_carousel {
        width: 100%;
    }
    .all_courses_carousel_body {
        position: relative;
        height : 80px;
    }
    .all_courses_carousel_body p {
        position: absolute;
        top : 0;
        left : 0;
        font-size : 14px;
        padding : 0 10px;
    }
    .all_courses_body_price{
        display : flex;
        align-items : center;
        justify-content : space-between;
    }
    .all_courses_body_price p {
        font-size: 12px;
    }

    .all_courses_body_price button {
        font-size: 12px;
        margin-left: 0px;
        padding: 10px 10px;
    }
    .all_courses_carousel_card_footer_left{
        font-size: 10px;
        width: 70px;
        padding: 10px 5px;
    }
    .all_courses_carousel_card_footer_right{
        font-size: 10px;
        width : 70px;
        padding: 10px 5px;
    }
    .all_courses_body_carousel .p-carousel-item{
        padding-right : 8px;
    }
    /*p-carousel-item-active p-carousel-item-start p-carousel-item-end*/
}

