@media (max-width: 576px) {
    .landing_page {
        background: #000;
        padding: 0 15px;
        box-sizing: border-box;
    }

    /*header*/
    .landing_page_header {
        position: sticky;
        top: 0;
        left: 0;
    }

    .landing_page_header_logo h2 {
        font-size: 20px;
    }

    .landing_page_header_right_inner {
        display: none;
    }

    .landing_page_header_right_burger {
        display: block;
    }

    .landing_page_header_right_burger img {
        transform: scale(0.8);
    }

    /*for section 1*/
    .landing_page_section_1 {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .landing_page_section_1_left {
        order: 1;
    }

    .landing_page_section_1_right {
        order: 0;
        width: 270px;
        height: 270px;
        margin: 0 auto;
    }

    .landing_page_section_1_left_logo {
        width: 100%;
        justify-content: center;
        height: 40px;
        margin-top: 10px;
    }

    .landing_page_section_1_left_logo h1 {
        font-size: 28px;
        text-align: center;
    }

    .landing_page_section_1_left p {
        font-size: 14px;
        text-align: center;
        margin-top: 15px;
        padding: 0 15px;
    }

    .landing_page_section_1_left_link {
        display: flex;
        justify-content: center;
        margin-top: 35px;
    }

    .landing_page_section_1_left_link a {
        display: none;
    }

    .landing_page_section_1_left_link_anim {
        width: 220px;
        height: 45px;
    }

    .landing_page_section_1_left_link_anim p {
        top: 11%;
        left: 3%;
        font-size: 12px;
        line-height: 0;
    }

    /*for section 2*/
    .landing_page_section_2 {

    }

    .landing_page_section_2_total_mobile {
        display: block;
        border-radius: 90px;
        background: radial-gradient(circle at 50% 20%, rgba(147, 0, 0, 0.95) 55%, #000 100%) !important;
        box-shadow: 0px 4px 4px 0px rgba(192, 183, 232, 0.01);
        width: 100%;
        grid-gap: 10px;
        /*height: 120px;*/
        margin-top: 45px;
        padding: 10px 5px;
    }

    .landing_page_section_2_total {
        display: none;
    }

    .landing_page_section_2_motiv {
        margin-top: 45px;
        padding-bottom: 20px;
        height: auto;
        justify-content: center !important;
    }

    .landing_page_section_2_motiv_inner:first-child {
        display: none;
    }

    .landing_page_section_2_motiv_inner {

    }

    .landing_page_section_2_motiv_inner:last-child {
        display: none;
    }

    .landing_page_section_2_motiv_inner:nth-child(2) img {
        width: 250px;
        height: 150px;
        background-repeat: no-repeat;
    }

    .landing_page_section_2_motiv_inner h2 {
        font-size: 16px;
        line-height: 16px;
    }

    .landing_page_section_3 {
        padding-top: 0;
        margin-top: 20px;
    }

    .landing_page_section_3_verticalLine {
        left: 0;
    }

    .landing_page_section_3_verticalLine_square {
        width: 15px;
        height: 15px;
    }

    .landing_page_section_3_animation {
        display: flex;
        flex-direction: column;
        /*padding-left: 15px;*/
        /*display : block;*/
    }

    .landing_page_section_3_animation_inner h2 {
        font-size: 14px;
        text-align: left;
    }

    .landing_page_section_3_animation_inner p {
        font-size: 14px;
    }

    .landing_page_section_3_animation_inner {
        display: block;
    }


    .landing_page_section_3_animation_inner:nth-child(2) {
        display: flex;
        flex-direction: column;
    }

    .landing_page_section_3_animation_inner:nth-child(2) .animated:last-child {
        order : 1;
    }

    .landing_page_section_3_animation_inner:nth-child(2) .animated:first-child {
        order : 2;
    }

    .landing_page_section_3_animation_inner1 {
        padding: 20px 0;
        /*padding-left: 15px;*/
    }

    .landing_page_section_3_animation_inner2 {
        height: 250px;
    }

    /*for section 4*/
    .landing_page_section_4 {
        padding-top: 50px;
    }

    .landing_page_section_4_top {
        width: 100% !important;
    }

    .landing_page_section_4_top p {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .landing_page_section_4_medium img {
        transform: scale(.8);
    }

    .landing_page_section_4_bottom {
        text-align: center;
        width: 100%;
    }

    .landing_page_section_4_bottom h2 {
        font-size: 16px;
    }

    .landing_page_section_4_bottom h1 {
        font-size: 18px;
        line-height: 0;
        text-align: center;
    }

    .landing_page_section_4_bottom p {
        font-size: 14px;
        /*line-height: 0;*/
    }

    .landing_page_section_5_carousel {
        width: 100%;
        display: block;
    }

    .landing_page_section_5_carousel_inner_card {
        /*width: 250px !important;*/
        /*height: 420px;*/
    }

    .landing_page_section_5_screen {
        margin-top: 55px;
    }

    .landing_page_section_5_screen_top {
        text-align: center;
    }

    .landing_page_section_5_screen_top h2 {
        font-size: 14px;
        padding: 0 25px;
    }

    .landing_page_section_5_screen_top h1 {
        font-size: 20px;
        margin-top: 11px;
        line-height: 20px;
    }

    .landing_page_section_5_screen_top p {
        font-size: 14px;
        margin-top: 25px;
        line-height: 16px;
    }

    .landing_page_section_5_screen_img {
        /*display: none;*/
    }

    .landing_page_section_5_screen_img_slider {
        display: inline-block;
        margin-top: 10px;
        width: 100%;
    }

    .landing_page_section_5_screen_img_slider img {
        width: 220px;
        height: 460px;
        background-size: 100%;
        margin: 0 auto;
    }

    .landing_page_section_5_screen_subscription {
        margin-top: 35px;
        height: 93px;
    }

    .landing_page_section_5_screen_subscription_inner h2 {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        padding: 0 10px;
    }

    .landing_page_section_5_screen_subscription_inner p {
        font-size: 14px;
        margin-top: 15px;
        line-height: 0px;
        text-align: center;
    }

    .landing_page_section_5_screen_subscription_button {
        margin-top: 30px;
    }

    .landing_page_section_5_screen_subscription_button_inner {
        width: 200px;
        height: 40px;
    }

    .landing_page_section_5_screen_subscription_button_text {
        margin-top: 35px;
    }

    .landing_page_section_5_screen_subscription_button_text img {
        margin-top: 50px;
        display: none;
    }

    .landing_page_section_5_screen_subscription_button_text h1 {
        font-size: 25px;
    }

    .landing_page_section_5_screen_subscription_button_text p {
        font-size: 14px;
        line-height: 16px;
        margin-top: 29px;
        text-align: left;
    }

    .landing_page_section_5_screen_news {
        margin-top: 25px;
    }

    .landing_page_section_5_screen_news_number {
        display: none;
    }

    .landing_page_section_5_screen_news_number_mobile {
        display: block;
    }

    .landing_page_section_5_screen_news_number_inner_top_1 {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(15, 19, 29, 1);
        margin: 0 auto;
    }

    .landing_page_section_5_screen_news_number_inner_top {
        width: 170px;
        height: 170px;
    }

    .landing_page_section_5_screen_news_number_inner_bottom {
        margin-top: 42px;
    }

    .landing_page_section_5_screen_news_number_inner_bottom img {
        display: none;
    }

    .landing_page_section_5_screen_news_number_inner_bottom h6 {
        font-size: 15px;
        display: inline !important;
    }

    .landing_page_section_5_screen_news_number_inner_bottom h6 br {
        display: none
    }

    .landing_page_section_5_screen_news_carousel {
        margin-top: 20px;
    }

    .landing_page_section_5_screen_news_carousel_inner {
        margin-top: 20px;
    }

    .landing_page_section_5_screen_news_carousel_inner_card {
        height: 450px;
    }

    .landing_page_section_5_screen_news_carousel_line {
        background: linear-gradient(270deg, #000000 0%, #FF4F4F 49.54%, #000000 100%);
    }

    .landing_page_section_6_subscribe {
        margin-top: 40px;
    }

    .landing_page_section_6_subscribe_text h2 {
        font-size: 15px;
        line-height: 20px;
    }

    .landing_page_section_6_subscribe_text p {
        font-size: 14px;
        line-height: 16px;
        margin-top: 15px;
    }

    .landing_page_section_6_subscribe_cards {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap: 16px;
        justify-content: center;
        margin-top: 25px;
    }

    .landing_page_section_6_subscribe_cards_card {
        margin: 0 auto;
        padding: 20px 30px;
        width: 100%;
    }

    .landing_page_section_6_subscribe_cards_card_title h2 {
        font-size: 30px;
    }

    .landing_page_section_6_subscribe_cards_card_price span {
        font-size: 13px;
    }

    .landing_page_section_6_subscribe_cards_card_price h2 {
        font-size: 40px;
    }

    .landing_page_section_6_subscribe_cards_card ul li {
        font-size: 13px;
    }

    .landing_page_section_6_subscribe_cards_card_bottom {
        margin-top: 30px;
    }

    .landing_page_section_6_subscribe_cards_card_bottom button {
        width: 200px;
        height: 44px;
        font-size: 12px;
    }

    .landing_page_section_6_eVideos {
        margin-top: 30px;
    }

    .landing_page_section_6_eVideos_text h2 {
        font-size: 20px;
        line-height: 30px;
    }

    .landing_page_section_6_eVideos_text p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 11px;
    }

    .landing_page_section_6_eVideos_body {
        margin-top: 11px;
    }

    .landing_page_section_6_eVideos_body_card {
        display: flex;
        flex-direction: column;
        border-radius: 35px;
        padding: 25px 27px;
        margin-bottom: 23px;
        flex-grow: 1;
    }

    .landing_page_section_6_eVideos_body_card_left {
        order: 1;
    }

    .landing_page_section_6_eVideos_body_card_right {
        order: 0;
    }

    .landing_page_section_6_eVideos_body_card_right iframe {
        /*width : 290px;*/
        /*margin : 0 auto;*/
        height: 90%;
    }

    .landing_page_section_6_eVideos_body_card_left h2 {
        font-size: 14px;
        line-height: 20px;
    }

    .landing_page_section_6_eVideos_body_card_left h3 {
        font-size: 14px;
        line-height: 20px;
        margin-top: 11px;
    }

    .landing_page_section_6_eVideos_body_card_left p {
        font-size: 12px;
        line-height: 14px;
        margin-top: 12px;
    }


    .landing_page_section_6_eVideos_body_button button {
        width: 200px;
        height: 44px;
        font-size: 12px;
    }

    .landing_page_section_7_note {
        margin-top: 15px;
    }

    .landing_page_section_7_note_text h2 {
        font-size: 25px;
        line-height: 37px;
        text-align: center;
    }

    .landing_page_section_7_note_text p {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
    }

    .landing_page_section_7_note_cards {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        margin-top: 24px;
    }

    .landing_page_section_7_note_cards_card {
        padding: 19px 20px;
        border-radius: 50px;
        width: 85%;
        margin: 0 auto;
    }

    .landing_page_section_7_note_cards_card_img {

    }

    .landing_page_section_7_note_cards_card h2 {
        font-size: 15px;
        line-height: 22px;
    }

    .landing_page_section_7_note_cards_card p {
        font-size: 12px;
        line-height: 20px;
        text-align: left;
    }

    .landing_page_section_7_note_bottom {
        margin: 20px 0;
    }

    .landing_page_section_7_note_bottom h3 {
        font-size: 10px;
        line-height: 12px;
        margin-top: 0;
    }

    .landing_page_section_7_note_bottom h2 {
        font-size: 25px;
        line-height: 37px;
    }

    .landing_page_section_7_note_line {
        background: linear-gradient(270deg, #000000 0%, #FF4F4F 49.54%, #000000 100%);
        height: 1px;
        border-image-source: linear-gradient(270deg, #000000 0%, #FF4F4F 49.54%, #000000 100%);
    }

    .landing_page_section_8_ferrari {
        margin-top: 30px;
    }

    .landing_page_section_8_ferrari_top h2 {
        font-size: 12px;
        line-height: 18px;
    }

    .landing_page_section_8_ferrari_top h1 {
        font-size: 25px;
        line-height: 37px;
    }

    .landing_page_section_8_ferrari_top h4 {
        font-size: 12px;
        line-height: 14px;
    }

    .landing_page_section_8_ferrari_top_inner {
        width: 200px;
        height: 80px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 20px;
    }

    .landing_page_section_8_ferrari_top_inner2 {
        width: 200px;
        height: 80px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 20px;
    }

    .landing_page_section_6_subscribe_cards_title h2 {
        font-size: 15px;
        line-height: 22px;
        margin-top: 32px;
    }

    .landing_page_section_8_ferrari_button {
        margin: 25px 0;
    }

    .landing_page_section_8_ferrari_button button {
        width: 200px;
        height: 45px;
        font-size: 12px;
    }

    .landing_page_section_7_note_line {
        background: linear-gradient(270deg, #000000 0%, #FF4F4F 49.54%, #000000 100%);
        height: 1px;
        border-image-source: linear-gradient(270deg, #000000 0%, #FF4F4F 49.54%, #000000 100%);
    }

    .landing_page_section_9_questions {
        margin-top: 20px;
    }

    .landing_page_section_9_questions_title h1 {
        font-size: 25px;
        line-height: 37px;
    }

    .landing_page_section_9_questions_body {
        margin-top: 15px;
    }

    .landing_page_section_9_questions_body .ant-collapse {
        border: none;
        border-radius: 20px;
        margin-bottom: 16px;
        background: rgba(15, 19, 29, 1) !important;
    }

    .landing_page_section_9_questions_body .ant-collapse-item {
        border: 0 !important;
    }

    .landing_page_section_9_questions_body .ant-collapse-content {

    }

    .landing_page_section_9_questions_body .ant-collapse-header-text {
        font-size: 12px;
        line-height: 14px;
    }

    .landing_page_section_9_questions_body .ant-collapse-content-box p {
        font-size: 10px;
        line-height: 12px;
    }

    .landing_page_section_9_questions_body .ant-collapse-header {
        position: relative;
        padding: 22px 16px !important;
    }

    .landing_page_section_9_questions_body .ant-collapse-expand-icon {
        position: absolute;
        right: 10px;
        top: 30%;
    }

    .landing_page_section_9_questions_body .ant-collapse-expand-icon .anticon {
        color: #FFFFFF !important;
        font-size: 15px !important;
    }

    .landing_page_section_footer {
        margin-top: 20px;
    }

    .landing_page_section_footer_inner_logo {
        width: 60px;
        height: 60px;
        margin: 0 auto;
    }

    .landing_page_section_footer_inner_logo img {
        width: 100%;
        height: 100%;
    }

    .landing_page_section_footer_inner {
        display: flex;
        flex-direction: column;
    }

    .landing_page_section_footer_inner_links {
        grid-template-columns: auto;
    }

    .landing_page_section_footer_inner_links:nth-child(3) {
        /*display: none;*/
        display: inline-block;
    }

    .landing_page_section_footer_inner_links:nth-child(3) {
        display: flex;
        justify-content: center;
    }

    .landing_page_section_footer_inner_links:nth-child(3) ul {
        display: inline-block !important;
        text-align: center;
    }

    .landing_page_section_footer_inner_links ul {
        display: flex;
        justify-content: space-around;
    }

    .landing_page_section_footer_inner_links ul li {

    }

    .landing_page_section_footer_inner_links ul li a {
        font-size: 10px;
        line-height: 12px;
    }


    .landing_page_section_footer_inner_links_line {
        display: none;
    }


    .slick-list {
        width: 100% !important;
        background: transparent !important;
        margin: 0 auto !important;
        padding: 0;
    }

    .landing_page_section_footer_inner_links_messenger {
        margin: 14px auto;
        justify-content: center;
    }

    .landing_page_section_footer_inner_links p {
        font-size: 12px;
        margin-top: 17px;
        text-align: center;
    }

    .landing_page_section_footer_line {
        background: linear-gradient(270deg, #000000 0%, #FF4F4F 49.54%, #000000 100%);
        border-image-source: linear-gradient(270deg, #000000 0%, #FF4F4F 49.54%, #000000 100%);
        height: 2px;
        margin-top: 30px;
    }

    .landing_page_section_footer_bottom {
        padding: 18px 0;
        text-align: center;
    }

    .landing_page_section_footer_bottom p {
        font-size: 8px;
        line-height: 10px;
    }

    .slick-track {
        padding: 10px 0 !important;
        border: none !important;
        box-shadow: none !important;
        z-index: 0 !important;
        background: transparent !important;
    }

    .slick-prev {
        left: 17px !important;
        z-index: 10;
    }

    .slick-next {
        right: 20px;
        z-index: 10;
    }


    .slick-slide {
        text-align: center !important;
    }

    /*slick-active slick-current*/

}