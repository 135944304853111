.landing_page {
    background: #000;
    /*padding: 0 90px;*/
}

@media only screen and (min-width: 1300px) {
    .landing_page {
        padding: 0 90px;
        /*margin: 0 90px;*/
    }
}

@media only screen and (min-width: 1498px) {
    .landing_page {
        padding: 0 120px;
    }
}

@media only screen and (min-width: 1686px) {
    .landing_page {
        padding: 0 190px;
    }
}

@media only screen and (min-width: 1800px) {
    .landing_page {
        padding: 0 270px;
    }
}

@media only screen and (min-width: 2000px) {
    .landing_page {
        padding: 0 360px;
    }
}

.landing_page_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 40px 0;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background: black;
    z-index: 1000;
}

.landing_page_header_logo {
    display: flex;
    align-items: center;
}

.landing_page_header_logo a {
    display: flex;
    align-items: center;
}

.landing_page_header_logo h2 {
    font-family: Sequel100Black-75;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    padding: 0;
}

.landing_page_header_logo h2:first-child {
    color: #F00;
}

.landing_page_header_logo h2:last-child {
    color: #FFFFFF;
    margin-left: 10px;
}

.landing_page_header_right_inner {
    display: flex;
    justify-content: end;
    align-items: center;
}

.landing_page_header_right_burger {
    display: none;
}

.landing_page_header_right_inner a {
    text-decoration: none;
}

.landing_page_header_right_links a {
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 25px;
    cursor: pointer;
    text-decoration: none;
}

.landing_page_header_right_registr {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    border: 2px solid #FFF;
    width: 205px;
    height: 40px;
    flex-shrink: 0;
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.landing_page_header_right_registr:hover {
    color: black;
    background: #F4F4F4;
}

.landing_page_header_right_login {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background: linear-gradient(90deg, #FA4343 0%, #A50000 100%);
    width: 120px;
    height: 40px;
    flex-shrink: 0;
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 35px;
    cursor: pointer;
}

.landing_page_header_right_login:hover {
    /*background: linear-gradient(90deg, #A50000 100%, #FA4343 20%);*/
    background: linear-gradient(90deg, #FA4343 70%, #A50000 100%);
    /*color: #A50000;*/
    /*background: #F4F4F4;*/
}

.landing_page_section_1 {
    display: grid;
    grid-template-columns: 2fr 1.1fr;
    /*grid-gap: 10px;*/
    margin-top: 50px;
}

.landing_page_section_1_right {
    position: relative;
}

.landing_page_section_1_right video {
    position: absolute;
    transform: scale(1.4);
    top: 0;
}

.landing_page_section_1_left {

}

.landing_page_section_1_left_logo {
    display: flex;
    align-items: center;
    height: 80px;
}

.landing_page_section_1_left_logo {
    position: relative;
    overflow: hidden;
}

.landing_page_section_1_left_logo_changeText {
    animation-name: fallAnimation;
    animation-duration: 2s;
    animation-timing-function: revert;
    animation-direction: normal;
    animation-iteration-count: infinite;
}

@keyframes fallAnimation {
    0% {
        transform: translateY(-70px);
    }
    50% {
        transform: translateY(5px);
    }
    70% {
        transform: translateY(0px);
        animation-delay: 1s;
    }
    100% {
        transform: translateY(70px);
    }
}

.landing_page_section_1_left_logo h1 {
    color: #F00;
    font-family: Sequel100Black-75;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.landing_page_section_1_left_logo h1:first-child {
    color: #F00;
}

.landing_page_section_1_left_logo h1:last-child {
    color: #FFFFFF;
    margin-left: 15px;
}

.landing_page_section_1_left p {
    color: #F4F4F4;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 50px;
}

.landing_page_section_1_left_link {
    display: flex;
    align-items: center;
    margin-top: 100px;
    width: fit-content;
}

.landing_page_section_1_left_link_anim {
    position: relative;
    width: 224px;
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.landing_page_section_1_left_link_anim p {
    position: absolute;
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    top: -80%;
    left: 11%;
}

.landing_page_section_1_left_link button {
    border: 0;
    width: 214px;
    height: 48px;
    border-radius: 40px;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.landing_page_section_1_left_link a {
    /*margin-left: 45px;*/
}

.landing_page_section_1_right {
    display: flex;
    justify-content: center;
}

.landing_page_section_1_right video {
    width: 100%;
    height: 100%;
}

.landing_page_section_2_total_mobile {
    display: none;
}
.landing_page_section_2_total_mobile_inner{

}

.landing_page_section_2_total {
    border-radius: 90px;
    /*border: 1px solid #000;*/
    background: radial-gradient(circle at 50% 20%, rgba(147, 0, 0, 0.95) 55%, #000 100%);
    box-shadow: 0px 4px 4px 0px rgba(192, 183, 232, 0.01);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    height: 128px;
    margin-top: 97px;
}

.landing_page_section_2_total_inner {
    text-align: center;
    width: 100%;
}

.landing_page_section_2_total_inner h2 {
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.landing_page_section_2_total_inner p {
    color: #F4F4F4;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.landing_page_section_2_total_inner:nth-child(2) {
    stroke-width: 1px;
    border-left: 2px solid rgba(192, 183, 232, 0.33);
    border-right: 2px solid rgba(192, 183, 232, 0.33);
}

.landing_page_section_2_motiv {
    display: flex;
    justify-content: space-between;
    height: 400px;
    margin-top: 99px;
}

.landing_page_section_2_motiv_inner {
    text-align: center;
}

.landing_page_section_2_motiv_inner:nth-child(1) img {
    width: 270px;
    height: 270px;
}

.landing_page_section_2_motiv_inner:nth-child(1) {
    display: flex;
    align-items: end;
    justify-content: start;
}

/*.landing_page_section_2_motiv_inner:nth-child(2) {*/
/*    width: 300px;*/
/*    height: 200px;*/
/*}*/
.landing_page_section_2_motiv_inner:nth-child(2) img {
    width: 300px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    object-fit: inherit;
}

.landing_page_section_2_motiv_inner:nth-child(3) img {
    width: 270px;
    height: 270px;
}

.landing_page_section_2_motiv_inner:nth-child(3) {
    display: flex;
    align-items: start;
    justify-content: end;
}

.landing_page_section_2_motiv_inner h2 {
    color: #F4F4F4;
    text-align: left;
    font-family: Sequel100Black-75;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 100% */
}


.landing_page_section_3 {
    padding-top: 50px;
    position: relative;
    /*background: yellow;*/
}

.landing_page_section_3_verticalLine {
    /*will-change: width, height, opacity;*/
    opacity: 1;
    z-index: 5;
    width: 2px;
    height: 0px;
    background-image: linear-gradient(to top, #fff, rgba(255, 255, 255, .73) 51%, rgba(255, 255, 255, 0));
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0;
    left: 50%;
    transition: linear;
    transition: height 0.1s ease-out;
}

.landing_page_section_3_verticalLine_square {
    width: 20px;
    height: 20px;
    background: #FFF;
    transform: rotate(45deg);
    transition-timing-function: linear;
    position: absolute;
    top: auto;
    bottom: 0%;
    /*transition: height 0.2s ease-out;*/
}

.landing_page_section_3_animation {
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr;*/
    /*grid-gap: 20px;*/
}

.landing_page_section_3_animation_inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.landing_page_section_3_animation_inner h2 {
    color: #F4F4F4;
    text-align: center;
    font-family: Sequel100Black-75;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.landing_page_section_3_animation_inner p {
    color: #F4F4F4;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.landing_page_section_3_animation_inner img {
    margin-right: 16px;
}

.landing_page_section_3_animation_inner1 {
    padding: 40px 0;
}

.landing_page_section_3_animation_inner2 {
    /*width: 80%;*/
    /*height: 366px;*/
    /*flex-shrink: 0;*/
    /*background: radial-gradient(50% 50% at 50% 50%, #5A0404 0%, #000 100%);*/
    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing_page_section_3_animation_inner1_top {
    display: flex;
    align-items: center;
}

.landing_page_section_3_animation_inner1_bottom {
    margin-left: 27px;
    margin-top: 16px;
}


.landing_page_section_4 {
    padding-top: 150px;
}

.landing_page_section_4_top {
    width: 733px;
    margin: 0 auto;
}

.landing_page_section_4_top p {
    color: #F4F4F4;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;
}

.landing_page_section_4_medium {
    text-align: center;
    display: flex;
    justify-content: center;
}

.landing_page_section_4_bottom {
    text-align: center;
}

.landing_page_section_4_bottom h2 {
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.landing_page_section_4_bottom h1 {
    color: #F00;
    text-shadow: 0px 0px 30px rgba(255, 0, 0, 0.50);
    font-family: Sequel100Black-75;
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 25px;
}

.landing_page_section_4_bottom p {
    color: #F4F4F4;
    font-family: Inter;
    font-size: 20px;
    /*font-style: normal;*/
    font-weight: 600;
    line-height: 20px;
    margin-top: 23px;
}

.landing_page_section_5_carousel {
    margin-top: 40px;
}

/* Carousel.css */
.landing_page .slick-slider {
    border: none;
    box-shadow: none;
}

.landing_page .slick-list {
    padding-left: 7px;
    background: black;
    z-index: 0;
}

.landing_page .slick-dots {
    display: none !important;
}

.landing_page .slick-prev {
    left: -40px;
}

.landing_page .slick-prev:before {
    font-size: 35px;
    color: #A50000;
}

.landing_page .slick-next {
    /*left: -50px;*/
}

.landing_page .slick-next:before {
    font-size: 35px;
    color: #A50000;
}

.landing_page .slick-track {
    padding: 30px 0;
    border: none !important;
    box-shadow: none !important;
    z-index: 0 !important;
    background: transparent;
}

/* Carousel.css */


.landing_page_section_5_carousel_inner_card {
    border-radius: 40px;
    border: 1px solid red;
    /*height: 430px;*/
    /*width: 300px !important;*/
    padding: 8px;
    box-sizing: border-box;
    background: black;
    margin: 0 5px;
}

.landing_page_section_5_carousel_inner_head {
    height: 180px;
    width: 100%;
    background: radial-gradient(50% 50% at 50% 50%, #5A0404 60%, #000 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
}

.landing_page_section_5_carousel_inner_head img {
    height: 100%;
    width: 100%;
    border-radius: 40px;
}

.landing_page_section_5_carousel_inner_head h2 {
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.landing_page_section_5_carousel_inner_card_body {
    text-align: center;
    margin-top: 20px;
}

.landing_page_section_5_carousel_inner_card_body h2 {
    color: #F4F4F4;
    font-family: BebasNeue;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.landing_page_section_5_carousel_inner_card_body_line {
    width: 100%;
    display: flex;
    justify-content: center;
}

.landing_page_section_5_carousel_inner_card_body_line .line {
    background: rgba(192, 183, 232, 0.33);
    height: 1px;
    width: 154px;
}

.landing_page_section_5_carousel_inner_card_body_text {
    margin-top: 20px;
    background: radial-gradient(50% 54% at 50% 50%, #5A0404 50%, #000 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 40px;
    height: 120px;
    overflow-y: auto;
}

.landing_page_section_5_carousel_inner_card_body_text p {
    color: #F4F4F4;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.landing_page_section_5_screen {
    margin-top: 100px;
}

.landing_page_section_5_screen_top {
    text-align: center;
}

.landing_page_section_5_screen_top h2 {
    font-family: Sequel100Black-75;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #FFFFFF;
}

.landing_page_section_5_screen_top h1 {
    font-family: Sequel100Black-75;
    font-size: 60px;
    font-weight: 400;
    line-height: 90px;
    color: #F00;
    text-shadow: 0px 0px 30px rgba(255, 0, 0, 0.50);
    margin-top: 45px;
}

.landing_page_section_5_screen_top p {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 45px;
}

.landing_page_section_5_screen_img {
    margin-top: 40px;
}
.landing_page_section_5_screen_img_inner{
    height: 430px;
    margin : 0 5px;
}
.landing_page_section_5_screen_img_inner img{
    height: 100%;
    width : 100%;
}

.landing_page_section_5_screen_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.landing_page_section_5_screen_subscription {
    height: 162px;
    width: 100%;
    border-radius: 90px;
    background-image: url("../../images/landingImage/fonImg.svg");
    background-size: cover;
    background-position: center;
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing_page_section_5_screen_subscription_inner h2 {
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.landing_page_section_5_screen_subscription_inner p {
    color: #F4F4F4;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
    text-align: center;
}

.landing_page_section_5_screen_subscription_button {
    /*background: green;*/
    display: flex;
    justify-content: center;
    margin-top: -24px;
}

.landing_page_section_5_screen_subscription_button_inner {
    width: 214px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 40px;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    color: #F4F4F4;
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: animButton 2s;
    animation-timing-function: inherit;
    animation-iteration-count: infinite;
}

@keyframes animButton {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
    /*0% {*/
    /*    background: linear-gradient(90deg, #e80000 0%, #FF4F4F 0%);*/
    /*}*/
    /*10% {*/
    /*    background: linear-gradient(80deg, #e80000 0%, #FF4F4F 10%);*/
    /*}*/
    /*20% {*/
    /*    background: linear-gradient(90deg, #e80000 0%, #FF4F4F 20%);*/
    /*}*/
    /*30% {*/
    /*    background: linear-gradient(90deg, #e80000 0%, #FF4F4F 30%);*/
    /*}*/
    /*40% {*/
    /*    background: linear-gradient(90deg, #e80000 0%, #FF4F4F 40%);*/
    /*}*/
    /*50% {*/
    /*    background: linear-gradient(90deg, #e80000 0%, #FF4F4F 50%);*/
    /*}*/
    /*60% {*/
    /*    background: linear-gradient(90deg, #680000 0%, #FF4F4F 60%);*/
    /*}*/
    /*70% {*/
    /*    background: linear-gradient(90deg, #680000 0%, #FF4F4F 70%);*/
    /*}*/
    /*80% {*/
    /*    background: linear-gradient(90deg, #680000 0%, #FF4F4F 80%);*/
    /*}*/
    /*90% {*/
    /*    background: linear-gradient(90deg, #680000 0%, #FF4F4F 90%);*/
    /*}*/
    /*100% {*/
    /*    background: linear-gradient(90deg, #680000 0%, #FF4F4F 100%);*/
    /*}*/
}

.landing_page_section_5_screen_subscription_button_text {
    margin-top: 100px;
}

.landing_page_section_5_screen_subscription_button_text img {
    margin-top: 50px;
}

.landing_page_section_5_screen_subscription_button_text h1 {
    font-size: 65px;
}

.landing_page_section_5_screen_subscription_button_text p {
    font-size: 25px;
}


.landing_page_section_5_screen_news {
    margin-top: 95px;
}

.landing_page_section_5_screen_news_number {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    background-image: url("../../images/landingImage/Vector 15.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.landing_page_section_5_screen_news_number_mobile {
    display: none;
}
.landing_page_section_5_screen_news_number_mobile_1{

}

.landing_page_section_5_screen_news_number_inner {
    text-align: center;
}

.landing_page_section_5_screen_news_number_inner_top {
    background: linear-gradient(251.27deg, #FF4F4F 12.87%, #A50000 89.33%);
    width: 190px;
    height: 190px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.landing_page_section_5_screen_news_number_inner_top h2 {
    font-family: Montserrat;
    font-size: 64px;
    font-weight: 700;
    line-height: 78px;
    color: #FFFFFF;
}

.landing_page_section_5_screen_news_number_inner_bottom {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.landing_page_section_5_screen_news_number_inner_bottom img {
    height: 20px;
}

.landing_page_section_5_screen_news_number_inner_bottom h6 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.landing_page_section_5_screen_news_carousel {
    margin-top: 100px;
}

.landing_page_section_5_screen_news_carousel_text h1 {
    font-size: 45px;
    margin-top: 0;
}

.landing_page_section_5_screen_news_carousel_text p {
    font-size: 20px;
    margin-top: 10px;
}

.landing_page_section_5_screen_news_carousel_inner {
    margin-top: 30px;
}

.landing_page_section_5_screen_news_carousel_inner_card {
    box-shadow: 0px 0px 25px 0px rgba(255, 0, 0, 0.25);
    height: 400px;
    margin : 15px 5px;
    border-radius: 40px;
}


.landing_page_section_5_screen_news_carousel_inner_card img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 40px;
}

.landing_page_section_5_screen_news_carousel_line {
    background: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
    height: 1px;
    border-image-source: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
    margin: 0 auto;
    margin-top: 50px;
    width: 80%;
}

.landing_page_section_6_subscribe {
    margin-top: 70px;
}

.landing_page_section_6_subscribe_text {

}

.landing_page_section_6_subscribe_text h2 {
    font-family: Sequel100Black-75;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    color: #FFFFFF;
    text-align: center;
}

.landing_page_section_6_subscribe_text p {
    font-family: Inter;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 50px;
}

.landing_page_section_6_subscribe_cards {
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr 1fr;*/
    /*grid-gap: 90px;*/
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
    background: radial-gradient(50% 50% at 50% 50%, #5A0404 0%, #000000 100%);
}

.landing_page_section_6_subscribe_cards_card {
    background: rgba(0, 111, 72, 1);
    border-radius: 40px;
    padding: 32px 50px;
    width: 29%;
    /*flex-grow: 1;*/
}

.landing_page_section_6_subscribe_cards_card_title h2 {
    font-family: BebasNeue;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
}

.landing_page_section_6_subscribe_cards_card_price {
    margin-top: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing_page_section_6_subscribe_cards_card_price span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: right;
    color: rgba(255, 0, 0, 1);
    /*margin-right: 5px;*/
    width: 50%;
}

.landing_page_section_6_subscribe_cards_card_price h2 {
    font-family: BebasNeue;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
    width: 50%;
}

.landing_page_section_6_subscribe_cards_card ul li {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #FFFFFF;
}

.landing_page_section_6_subscribe_cards_card_price_redText {
    color: rgba(255, 0, 0, 1) !important;
    text-decoration: line-through !important;
}

.landing_page_section_6_subscribe_cards_card_bottom {
    display: flex;
    justify-content: center;
    margin-top: 57px;
    animation: animButton2 2s;
    animation-timing-function: inherit;
    animation-iteration-count: infinite;
}

@keyframes animButton2 {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(0.9);
    }
}

.landing_page_section_6_subscribe_cards_card_bottom button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    width: 264px;
    height: 67px;
    border: 0;
    border-radius: 40px;
    font-family: Sequel100Black-75;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.landing_page_section_6_eVideos {
    margin-top: 80px;
}

.landing_page_section_6_eVideos_text h2 {
    font-family: Sequel100Black-75;
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    color: rgba(255, 0, 0, 1);
}

.landing_page_section_6_eVideos_text p {
    font-family: Sequel100Black-75;
    font-size: 25px;
    font-weight: 400;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
}

.landing_page_section_6_eVideos_body {
    margin-top: 70px;
}

.landing_page_section_6_eVideos_body_card {
    background: rgba(15, 19, 29, 1);
    border-radius: 55px;
    padding: 20px 60px;
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    grid-gap: 10px;
    flex-grow: 1;
    margin-bottom: 50px;
    min-height: 290px;
}

.landing_page_section_6_eVideos_body_card_left h2 {
    font-family: Sequel100Black-75;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    color: #FFFFFF;
}

.landing_page_section_6_eVideos_body_card_left h3 {
    font-family: Sequel100Black-75;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    color: #FFFFFF;
    margin-top: 15px;
}

.landing_page_section_6_eVideos_body_card_left p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #FFFFFF;
    margin-top: 20px;
}

.landing_page_section_6_eVideos_body_card_right {
    border-radius: 35px;
    /*background: red;*/
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin : 10px 0;*/
}

.landing_page_section_6_eVideos_body_card_right iframe {
    border-radius: 35px;
    height: 90%;
}

.landing_page_section_6_eVideos_body_button {
    text-align: center;
}

.landing_page_section_6_eVideos_body_button_img {
    margin: 20px 0;
}

.landing_page_section_6_eVideos_body_button_img img {
    cursor: pointer;
}

.landing_page_section_6_eVideos_body_button button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    width: 264px;
    height: 67px;
    border-radius: 40px;
    font-family: Sequel100Black-75;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    border: 0;
    margin: 0 auto;
    cursor: pointer;
}

.landing_page_section_6_eVideos_body_line {
    background: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
    height: 1px;
    border-image-source: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
}

.landing_page_section_7_note {
    margin-top: 60px;
}

.landing_page_section_7_note_text h2 {
    font-family: Sequel100Black-75;
    font-size: 60px;
    font-weight: 400;
    line-height: 90px;
    text-align: center;
    color: rgba(255, 0, 0, 1);
}

.landing_page_section_7_note_text p {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}

.landing_page_section_7_note_cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 160px;
    margin-top: 100px;
}

.landing_page_section_7_note_cards_card {
    text-align: center;
    padding: 40px 20px;
    border-radius: 50px;
    background: linear-gradient(0deg, #0F131D, #0F131D);
    color: #FFFFFF;
}

.landing_page_section_7_note_cards_card:last-child {
    background: linear-gradient(0deg, #006F48, #006F48);
}

.landing_page_section_7_note_cards_card_img {

}

.landing_page_section_7_note_cards_card h2 {
    font-family: Sequel100Black-75;
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    color: rgba(255, 0, 0, 1);
}

.landing_page_section_7_note_cards_card p {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 30px;
}

.landing_page_section_7_note_bottom {
    margin: 60px 0;
    text-align: center;
}

.landing_page_section_7_note_bottom h3 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 20px;
}

.landing_page_section_7_note_bottom h2 {
    font-family: Sequel100Black-75;
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
    color: rgba(255, 0, 0, 1);
}

.landing_page_section_7_note_line {
    background: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
    height: 1px;
    border-image-source: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
}

.landing_page_section_8_ferrari {
    margin-top: 60px;
}

.landing_page_section_8_ferrari_top {
    text-align: center;
}

.landing_page_section_8_ferrari_top h2 {
    font-family: Sequel100Black-75;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    color: #FFFFFF;
}

.landing_page_section_8_ferrari_top h2 span {
    color: rgba(255, 0, 0, 1);
}

.landing_page_section_8_ferrari_top h1 {
    font-family: Sequel100Black-75;
    font-size: 64px;
    font-weight: 400;
    line-height: 97px;
    color: rgba(255, 0, 0, 1);
}

.landing_page_section_8_ferrari_top h4 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #FFFFFF;
    margin: 14px 0;
}

.landing_page_section_8_ferrari_top_inner {
    width: 389px;
    height: 160px;
    border-radius: 40px;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 19px;
}

.landing_page_section_8_ferrari_top_inner2 {
    width: 389px;
    height: 160px;
    border-radius: 40px;
    border: 2px solid rgba(244, 244, 244, 1);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.landing_page_section_6_subscribe_cards_title h2 {
    font-family: Sequel100Black-75;
    font-size: 35px;
    font-weight: 400;
    line-height: 53px;
    text-align: center;
    color: rgba(255, 0, 0, 1);
    margin-top: 45px;
}

.landing_page_section_8_ferrari_button {
    display: flex;
    justify-content: center;
    margin: 40px 0;
}

.landing_page_section_8_ferrari_button button {
    width: 233px;
    height: 52px;
    border-radius: 40px;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Sequel100Black-75;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    border: 0;
    cursor: pointer;
}

.landing_page_section_9_questions {
    margin-top: 20px;
}

.landing_page_section_9_questions_title h1 {
    font-family: Sequel100Black-75;
    font-size: 55px;
    font-weight: 400;
    line-height: 83px;
    text-align: center;
    color: rgba(255, 0, 0, 1);
}

.landing_page_section_9_questions_body {
    margin-top: 35px;
}

.landing_page_section_9_questions_body .ant-collapse {
    border: none;
    border-radius: 20px;
    margin-bottom: 16px;
    background: rgba(15, 19, 29, 1) !important;
}

.landing_page_section_9_questions_body .ant-collapse-item {
    border: 0 !important;
}

.landing_page_section_9_questions_body .ant-collapse-content {
    background: rgba(15, 19, 29, 1) !important;
    border: 0 !important;
    border-radius: 20px !important;
}

.landing_page_section_9_questions_body .ant-collapse-header-text {
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    color: #FFFFFF;
}

.landing_page_section_9_questions_body .ant-collapse-content-box p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    color: #FFFFFF;
}

.landing_page_section_9_questions_body .ant-collapse-header {
    position: relative;
    padding: 22px 16px !important;
}

.landing_page_section_9_questions_body .ant-collapse-expand-icon {
    position: absolute;
    right: 16px;
}

.landing_page_section_9_questions_body .ant-collapse-expand-icon .anticon {
    color: #FFFFFF !important;
    font-size: 20px !important;
}

.landing_page_section_footer {
    margin-top: 100px;
}

.landing_page_section_footer_inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.landing_page_section_footer_inner_logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing_page_section_footer_inner_links {
    display: grid;
    grid-template-columns: .5fr auto;
}

.landing_page_section_footer_inner_links_line {
    background: linear-gradient(179.87deg, #000000 0.47%, #000000 0.47%, #A50000 35.21%, #FF4F4F 68.91%, #000000 99.5%);
    border-image-source: linear-gradient(179.87deg, #000000 0.47%, #000000 0.47%, #A50000 35.21%, #FF4F4F 68.91%, #000000 99.5%);
    height: 100%;
    width: 3px;
}

.landing_page_section_footer_inner_links ul li {
    list-style-type: none;
}

.landing_page_section_footer_inner_links ul li a {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
}


.landing_page_section_footer_inner_links_messenger {
    display: flex;
    grid-gap: 13px;
    margin-top: 25px;
}

.landing_page_section_footer_inner_links_messenger img {

}

.landing_page_section_footer_inner_links_messenger a {
    cursor: pointer;
    text-decoration: none;
}

.landing_page_section_footer_inner_links p {
    font-family: Sequel100Black-75;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #FFFFFF;
    margin-top: 40px;
}

.landing_page_section_footer_line {
    background: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
    border-image-source: linear-gradient(270deg, #343045 0%, #C0B7E8 34.9%, #8176AF 68.75%, #343045 100%);
    height: 2px;
    margin-top: 70px;
}

.landing_page_section_footer_bottom {
    padding: 31px 0;
    text-align: center;
}

.landing_page_section_footer_bottom p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #FFFFFF;
}
