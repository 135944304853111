.library {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.library_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.library_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.library_body {
    position: relative;
    height: calc(100vh - 145px);
    overflow-x: auto;
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 10px;
}
.library_body_search{
    height: 40px;
    width: 100%;
    border: 2px solid rgba(255, 79, 79, 0.2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    padding: 0 15px;
    /*background: red;*/
}
.library_body_search img{
    /*width: 5%;*/
}
.library_body_search input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    margin-left: 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-family: Inter;
    font-size: 15px;
}

.library_body_table{
    height : 100%;
}

.library_body_table_text{
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 12.1px;
    text-align: center;
    color: #FFFFFF;
}
.library_body_table_text_pay{
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    /*width: 109px;*/
    /*height: 30px;*/
    padding : 10px 10px;
    box-sizing : border-box;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
}
.library_body_table_text_no_pay{
    border: 1px solid rgba(244, 244, 244, 1);
    /*width: 109px;*/
    /*height: 30px;*/
    padding : 10px 10px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.library_body_pagination{
    position: absolute;
    bottom : 10px;
    right : 10px;
}
/*pagination*/
.library_body_pagination .ant-pagination-item a{
    font-family: Inter;
    font-weight: 700;
    font-size: 12px;
    color : rgba(255, 79, 79, 1)!important;
}
.library_body_pagination .ant-pagination-item-link{
    color : rgba(255, 79, 79, 1)!important;
}
.library_body_pagination .ant-pagination-item-ellipsis{
    color : rgba(255, 79, 79, 1)!important;
}
.library_body_pagination .ant-pagination-item-link-icon{
    color : rgba(255, 79, 79, 1)!important;
}
.library_body_pagination .ant-pagination-item-active{
    border-color:rgba(255, 79, 79, 1)!important;
    background: transparent!important;
}
.library_body_pagination .ant-pagination-options{
    background: transparent!important;
    color : rgba(255, 79, 79, 1)!important;
}
/*pagination*/

/*table style*/
.library_body_table .ant-table {
    height: calc(100vh - 213px) !important;
    overflow-y: auto!important;
}

.library_body_table .ant-table {
    border-radius: 0 !important;
    /*height: 100%!important;*/
}

.library_body_table .ant-table-content {

}

.library_body_table .ant-table-cell {
    /*background: blue!important;*/
    border-radius: 0 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    color: #FFFFFF !important;
    text-align: center !important;
}

.library_body_table .ant-table {
    background: transparent !important;
}

.library_body_table .ant-table-thead .ant-table-cell {
    border-bottom: 0px solid rgba(255, 79, 79, 0.2) !important;
    background: transparent !important;
    /*position: sticky;*/
    /*top : 0;*/
    z-index: 100;
}

.library_body_table .ant-table-thead tr .ant-table-cell {
    height: 20px !important;
    padding: 10px 10px !important;
}

.library_body_table .ant-table-tbody {
    /*background: green!important;*/
    /*    height:100%!important;*/
    /*    overflow-y: auto!important;*/
}

.library_body_table .ant-empty-description {
    display: none!important;
}

.library_body_table .ant-table-tbody .ant-table-cell {
    border: 0 !important;
}

.library_body_table .ant-table-tbody .ant-table-cell-row-hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.library_body_table .ant-table-placeholder {
    background: transparent !important;
}

.library_body_table .ant-table-placeholder:hover {
    background: transparent !important;
}

.library_body_table .ant-table-placeholder:hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.library_body_table .ant-table-cell:hover {
    /*background: transparent!important;*/
}

/*table style*/


/*responsive*/

@media (max-width: 576px) {
    .library_header h2 {
        font-size: 14px;
    }
    .library_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }
    .library_body_table .ant-table-cell{
        font-size: 12px!important;
    }
    .library_body_table_text_pay{
        /*width: 80px;*/
        padding: 8px 5px;
    }
    .library_body_table_text_no_pay{
        /*width: auto;*/
        padding: 5px 5px;
    }
    .library_body_table .ant-table{
        height: calc(100vh - 323px)!important;
        overflow-y: auto!important;
    }
    .ant-table table{
        table-layout: fixed!important;
        width: 180% !important;
    }
}
