.payment_history {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.payment_history_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment_history_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}


.payment_history_body {
    position: relative;
    height: calc(100vh - 145px);
    /*overflow-y: auto;*/
    border: 4px solid rgba(255, 79, 79, 0.2);
}

.payment_history_body_table {
    height: 100%;
}

.payment_history_body_table_text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    /*line-height: 12.1px;*/
    text-align: center;
    color: #FFFFFF;
}

.payment_history_body_table_text_pay {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    /*width: 109px;*/
    /*height: 30px;*/
    /*gap: 0px;*/
    padding : 5px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.payment_history_body_table_text_no_pay {
    border: 1px solid rgba(244, 244, 244, 1);
    /*width: 109px;*/
    /*height: 30px;*/
    /*gap: 0px;*/
    padding : 5px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.payment_history_body_pagination {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

/*pagination*/
.payment_history_body_pagination .ant-pagination-item a {
    font-family: Inter;
    font-weight: 700;
    font-size: 12px;
    color: rgba(255, 79, 79, 1) !important;
}

.payment_history_body_pagination .ant-pagination-item-link {
    color: rgba(255, 79, 79, 1) !important;
}

.payment_history_body_pagination .ant-pagination-item-ellipsis {
    color: rgba(255, 79, 79, 1) !important;
}

.payment_history_body_pagination .ant-pagination-item-link-icon {
    color: rgba(255, 79, 79, 1) !important;
}

.payment_history_body_pagination .ant-pagination-item-active {
    border-color: rgba(255, 79, 79, 1) !important;
    background: transparent !important;
}

.payment_history_body_pagination .ant-pagination-options {
    background: transparent !important;
    color: rgba(255, 79, 79, 1) !important;
}

/*pagination*/

/*table style*/
.payment_history_body_table .ant-table {
    height: calc(100vh - 153px);
    overflow-y: auto;
}
.ant-table-thead .ant-table-cell {
    border-bottom: 4px solid rgba(255, 79, 79, 0.2) !important;
    background: transparent !important;
    /*position: sticky;*/
    /*top : 0;*/
    z-index: 100;
}

.payment_history_body_table .ant-table {
    border-radius: 0 !important;
    /*height: 100%!important;*/
}

.payment_history_body_table .ant-table-content {

}

.payment_history_body_table .ant-table-cell {
    /*background: blue!important;*/
    border-radius: 0 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    color: #FFFFFF !important;
    text-align: center !important;
}

.payment_history_body_table .ant-table {
    background: transparent !important;
}

.payment_history_body_table .ant-table-thead .ant-table-cell {
    border-bottom: 4px solid rgba(255, 79, 79, 0.2) !important;
    background: transparent !important;
    /*position: sticky;*/
    /*top : 0;*/
    z-index: 100;
}

.payment_history_body_table .ant-table-tbody {
    /*background: green!important;*/
    /*    height:100%!important;*/
    /*    overflow-y: auto!important;*/
}

.payment_history_body_table .ant-empty-description {
    display: none;
}

.payment_history_body_table .ant-table-tbody .ant-table-cell {
    border: 0 !important;
}

.payment_history_body_table .ant-table-tbody .ant-table-cell-row-hover {
    background: rgba(255, 79, 79, 0.2) !important;
}
.payment_history_body_table .ant-table-placeholder{
    background: transparent!important;
}
.payment_history_body_table .ant-table-placeholder:hover {
    background: transparent !important;
}

.payment_history_body_table .ant-table-placeholder:hover {
    background: rgba(255, 79, 79, 0.2) !important;
}


/*responsive*/

@media (max-width: 576px) {
    .payment_history_header h2 {
        font-size: 14px;
    }

    .payment_history_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        overflow-y: auto;
    }

    .payment_history_body_table .ant-table-cell {
        font-size: 12px !important;
    }

    .payment_history_body_table_text_pay {
        background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
        /*width: auto;*/
        padding: 8px 5px;
        /*height: 30px;*/
        /*gap: 0px;*/
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .payment_history_body_table_text_no_pay {
        border: 1px solid rgba(244, 244, 244, 1);
        /*width: auto;*/
        padding: 8px 5px;
        /*height: 30px;*/
        /*gap: 0px;*/
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .payment_history_body_table_text {
        min-width: 30px;
        overflow-x: hidden;
        margin: 0 auto;
        font-size: 10px;
        line-height: 10px;
    }
}

/*table style*/