.module {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.module_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.module_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.module_body {
    height: calc(100vh - 145px);
}

.module_body_inner_bloc_inner2 {
    height : calc(100vh - 205px);
    overflow-y: auto;
    border: 4px solid rgba(255, 79, 79, 0.2);
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    grid-gap: 0;
}

.module_body_inner_bloc2_inner2_inner_video_true{
    background: transparent;
    width: 100%;
    height: 500px;
    border-radius: 50px;
    position: relative;
}

.module_body_inner_bloc2_inner2_inner_inner_video{
    background: rgba(255, 79, 79, 0.4);
    width: 100%;
    height: 500px;
    border-radius: 50px;
    position: relative;
}
@media (max-width: 1600px) {
    .module_body_inner_bloc2_inner2_inner_video_true{
        background: transparent;
        width: 100%;
        height: 350px;
        border-radius: 50px;
        position: relative;
    }
    .module_body_inner_bloc2_inner2_inner_inner_video{
        background: rgba(255, 79, 79, 0.4);
        width: 100%;
        height: 350px;
        border-radius: 50px;
        position: relative;
    }
}
.module_body_inner_bloc2_inner2_inner_inner_video_icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}




.module_body_inner_bloc2_inner_inner_link {
    margin-bottom: 10px;
}

.module_body_inner_bloc2_inner_inner_link h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
    color: #FFFFFF;
}

.module_body_inner_bloc2_inner2_inner:first-child {
    border-right: 4px solid rgba(255, 79, 79, 0.2);
}

.module_body_inner_bloc2_inner2_inner h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
    color: #FFFFFF;
}

.module_body_inner_bloc2_inner_inner_link ul {
    margin-top: 5px;
}

.module_body_inner_bloc2_inner_inner_link ul li {
    list-style-type: none;
    margin-top: 5px;
}

.module_body_inner_bloc2_inner_inner_link ul li a {
    text-decoration: none;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.module_body_inner_bloc2_inner_inner_link ul li a img {
    margin-right: 5px;
}

.module_body_inner_bloc2_inner_inner_link ul li a span {
    display: flex;
    align-items: center;
}
.module_body_inner_bloc2_inner2_inner .ant-collapse {
    border: 0;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-item {
    border-radius: 0;
    border-bottom: 0 !important;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-header-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: #FFFFFF;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
    top: 22%;
    color: rgba(255, 79, 79, 1);
    font-size: 15px;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-expand-icon .anticon {
    font-size: 16px;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-header {
    padding: 12px 0px !important;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-content {
    border: 0 !important;
    border-radius: 0;
    background: transparent;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-content-active {
    background: transparent;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-content-box {
    /*background: black;*/
    padding: 0 16px;
    border-radius: 0;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-content-box ul li {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 12.1px;
    text-align: left;
    color: #FFFFFF;
    margin: 10px 0;
    cursor : pointer;
}

.module_body_inner_bloc2_inner2_inner .ant-collapse-content-box ul li::marker {
    color: rgba(255, 79, 79, 1);
}



.module_body_inner_bloc_inner2 .module_body_inner_bloc2_inner2_inner:last-child {
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}

.module_body_inner_bloc2_inner2_inner_video {
    background: rgba(255, 79, 79, 0.4);
    width: 100%;
    border-radius: 50px;
}

.module_body_inner_bloc2_inner2_inner {
    padding: 10px 15px;
    box-sizing: border-box;
}

.module_body_button {
    /*margin-top: 30px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    height : 60px;
    /*background: red;*/
}

.module_body_button button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 0;
    padding: 0 20px;
    margin : 0 10px;
    cursor: pointer;
}
.module_body_button button:first-child {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: white;

    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    margin : 0 10px;
    background: transparent;
    border : 1px solid white;
    cursor : pointer;
}

@media(max-width: 576px) {
    .module {
        overflow-y: -moz-scrollbars-none;
    }

    .module_header {
        height: 40px;
    }

    .module_header h2 {
        font-size: 14px;
    }

    .module_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }


    .module_body_inner_bloc_inner2 {
        display: block;
        height : calc(100vh - 320px);
        overflow-y: auto;
    }

    .module_body_inner_bloc2_inner2_inner:first-child {
        border-right: 0;
        border-bottom: 4px solid rgba(255, 79, 79, 0.2);
    }

    .module_body_inner_bloc2_inner2_inner {
        /*border: 4px solid rgba(255, 79, 79, 0.2);*/
        padding: 15px;
        box-sizing: border-box;
    }
    .module_body_inner_bloc_inner2 .module_body_inner_bloc2_inner2_inner:last-child {
        padding: 15px 20px;
    }
    .module_body_button button {
        font-size: 12px!important;
        height: 35px!important;
        padding: 0 15px!important;
        margin : 5px 10px!important;
    }

    .module_body_inner_bloc2_inner2_inner_video_true{
        width: 100%;
        height: 220px;
    }

    .module_body_inner_bloc2_inner2_inner_inner_video{
        width: 100%;
        height: 220px;
    }

}