
/*.header {*/
/*    background: rgba(0, 0, 0, 1);*/
/*    position: sticky;*/
/*    top: 0;*/
/*    z-index: 100;*/
/*    height: 75px;*/
/*    color: #FFFFFF;*/
/*    padding: 0 15px;*/
/*}*/

/*.header_line {*/
/*    width: 100%;*/
/*    height: 1px;*/
/*    background: rgba(192, 183, 232, 0.33);*/
/*}*/

/*.header_inner {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    width: 100% !important;*/
/*    height: 100% !important;*/
/*}*/

/*.header_left {*/

/*}*/

/*.header_left h1 {*/
/*    font-family: Inter;*/
/*    font-size: 24px;*/
/*    font-weight: 700;*/
/*    line-height: 29px;*/
/*}*/

/*.header_left h2 {*/
/*    font-family: Inter;*/
/*    font-size: 16px;*/
/*    font-weight: 700;*/
/*    line-height: 29px;*/
/*}*/

/*.header_right {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*}*/

/*.header_right_setting {*/
/*    border: 2px solid rgba(244, 244, 244, 1);*/
/*    height: 48px;*/
/*    width: 48px;*/
/*    border-radius: 50px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: start;*/
/*    margin-right: 35px;*/
/*    cursor: pointer;*/
/*}*/
/*.header_right_name {*/
/*    border: 2px solid rgba(244, 244, 244, 1);*/
/*    height: 48px;*/
/*    border-radius: 50px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    padding: 0 5px;*/
/*    cursor: pointer;*/
/*}*/

/*.header_right_name p {*/
/*    font-family: Inter;*/
/*    font-size: 12px;*/
/*    font-weight: 700;*/
/*    line-height: 15px;*/
/*    color: rgba(255, 79, 79, 1);*/
/*    margin: 0 15px;*/
/*}*/

/*.ant-layout {*/
/*    !*height: 100vh!important;*!*/
/*}*/

/*.ant-layout-sider {*/
/*    box-shadow: 0px 4px 30px 0px rgba(255, 0, 0, 0.3) !important;*/
/*    z-index: 1000 !important;*/
/*    background: black !important;*/
/*    overflow-y: auto !important;*/
/*    position: relative;*/
/*}*/

/*.ant-layout-sider-dark {*/

/*}*/

/*.left_sider_menu {*/
/*    position: relative;*/
/*}*/

/*.ant-menu {*/
/*    border-inline-end: none !important;*/
/*    background: black;*/
/*    padding: 15px 0;*/
/*    height: calc(100vh - 75px);*/
/*    overflow-y: auto !important;*/
/*    !*background: green;*!*/
/*}*/

/*@media (max-width: 576px) {*/

/*}*/

/*.ant-menu ::-webkit-scrollbar {*/
/*    display: none !important;*/
/*}*/


/*.logo {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    height: 75px;*/
/*    position: sticky;*/
/*    top: 0;*/
/*    z-index: 1;*/
/*    background: black;*/
/*}*/

/*.ant-menu-item-selected {*/
/*    background: black !important;*/
/*}*/

/*.ant-menu-item-selected img {*/
/*    filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(4834%) hue-rotate(356deg) brightness(97%) contrast(117%) !important;*/
/*    !*box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 1) !important;*!*/
/*}*/

/*.ant-menu-item-selected p {*/
/*    color: rgba(255, 0, 0, 1) !important;*/
/*}*/

/*.ant-menu-item-active {*/
/*    background-color: black !important;*/
/*}*/

/*.ant-menu-item-active img {*/
/*    filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(4834%) hue-rotate(356deg) brightness(97%) contrast(117%) !important;*/
/*    !*box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 1) !important;*!*/
/*}*/

/*.ant-menu-item-active p {*/
/*    color: rgba(255, 0, 0, 1) !important;*/
/*}*/

/*.saidbar_link {*/
/*    padding: 0 !important;*/
/*    height: 70px !important;*/
/*    width: 65px !important;*/
/*    margin: 0px auto !important;*/
/*    text-align: center;*/
/*    background: black;*/
/*    border-radius: 0 !important;*/
/*    margin-top: 10px !important;*/
/*}*/

/*.saidbar_link:active {*/
/*    !*background-color: black;*!*/
/*}*/

/*.ant-menu-title-content {*/
/*    height: 100%;*/
/*}*/

/*.saidbar_link img {*/
/*    height: 25px;*/
/*    margin-top: 5px;*/
/*}*/

/*.saidbar_link p {*/
/*    font-family: Inter;*/
/*    font-size: 9px;*/
/*    font-weight: 700;*/
/*    line-height: 11px;*/
/*    color: #FFFFFF;*/
/*}*/

/*.question {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    margin-top: 20px;*/
/*    padding: 20px 0;*/
/*}*/

/*.question img {*/
/*    width: 25px;*/
/*    height: 25px;*/
/*    cursor: pointer;*/
/*}*/

/*.question:hover img {*/
/*    filter: brightness(0) saturate(100%) invert(13%) sepia(100%) saturate(4834%) hue-rotate(356deg) brightness(97%) contrast(117%) !important;*/
/*}*/


/*.ant-layout {*/
/*    height: 100% !important;*/
/*    background: black;*/
/*    background-image: url('../../images/user/userDashboard/back2.png');*/
/*    background-size: 100%;*/
/*    !*background-size: cover;*!*/
/*    background-repeat: no-repeat;*/
/*    background-position: center;*/
/*}*/

/*!*.ant-layout*!*/
/*.site-layout {*/
/*    position: relative !important;*/
/*    height: 100% !important;*/
/*    !*padding-bottom: 15px!important;*!*/
/*}*/

/*.ant-layout-content {*/
/*    height: calc(100% - 5px) !important;*/
/*    padding: 15px;*/
/*    z-index: 0;*/
/*}*/

/*.site-layout-background {*/
/*    height: 100% !important;*/
/*    z-index: 0;*/
/*    overflow-y: auto;*/
/*}*/



