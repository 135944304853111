.certificate {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.certificate_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.certificate_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.certificate_body {
    height: calc(100vh - 145px);
    border: 4px solid rgba(255, 79, 79, 0.2);
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 10px;
    /*height: 100%;*/
    overflow-y: auto;
}


.certificate_body_card {
    border: 2px solid rgba(255, 79, 79, 0.2);
    border-radius: 20px;
    text-align: center;
    /*height: 200px;*/
    display: grid;
    grid-template-rows: 2fr 0.5fr;
    grid-gap: 10px;
    padding: 10px;
}

.certificate_body_card_header {
    width: 100%;
    height: 220px;
}
@media(max-width: 1400px) {
    .certificate_body_card_header {
        width: 100%;
        height: 150px;
    }
}
.certificate_body_card_header img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.certificate_body_card_body {

}

.certificate_body_card_body_button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    border-radius: 40px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: center;
    color: #FFFFFF;
    width: 60%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
}

@media (max-width: 576px) {

    .certificate_header {
        height: 40px;
    }

    .certificate_header h2 {
        font-size: 14px;
    }

    .certificate_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }
    .certificate_body {
        height: calc(100vh - 260px);
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        /*height: 100%;*/
        overflow-y: auto;
    }
    .certificate_body_card_header {
        width: 100%;
        height: 200px;
    }
}

