.cooperation {
    height: 100%;
    overflow-y: auto;
}

.cooperation_header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    height: 200px;
}

.cooperation_header_left_top {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
}

.cooperation_header_left_bottom {
    margin: 10px 0;
}

.cooperation_header_left_bottom p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #FFFFFF;
}

.cooperation_header_left_bottom p:nth-child(2), p:nth-child(3) {
    margin-top: 15px;
}

.cooperation_header_left_bottom_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(255, 79, 79, 0.2);
    padding-bottom: 10px;
    margin-top: 10px;
}

.cooperation_header_left_bottom_inner:nth-child(1) {
    margin-top: 0;
}

.cooperation_header_left_bottom_inner_inner {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #FFFFFF;
}

.cooperation_header_left_bottom_inner_inner button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #FFFFFF;
    border: 0;
    outline: none;
    border-radius: 10px;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
    /*background: #52c41a;*/
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
}

.ticket {
    color: #52c41a !important;
}

.ticketProgress {
    color: gray !important;
}

.cooperation__body {
    height: calc(100vh - 305px);
}

.cooperation__body_table {
    height: calc(100% - 40px);
}

.cooperation__body_table_table {
    /*height: calc(100% - 35px);*/
    height: calc(100% - 0px);
    overflow-y: auto;
}

/*table style*/
.cooperation__body_table_table .ant-table {

}

.cooperation__body_table_table .ant-table {
    border-radius: 0 !important;
}

.cooperation__body_table_table .ant-table-content {

}

.cooperation__body_table_table .ant-table-cell {
    border-radius: 0 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    color: #FFFFFF !important;
    text-align: center !important;

}

.cooperation__body_table_table .ant-table {
    background: transparent !important;
}

.cooperation__body_table_table .ant-table-thead .ant-table-cell {
    border-bottom: 4px solid rgba(255, 79, 79, 0.2) !important;
    background: transparent !important;
    z-index: 100;
}

.cooperation__body_table_table .ant-table-tbody {

}

.cooperation__body_table_table .ant-empty-description {
    display: none;
}

.cooperation__body_table_table .ant-table-tbody .ant-table-cell {
    border: 0 !important;
}

.cooperation__body_table_table .ant-table-tbody .ant-table-cell-row-hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

.cooperation__body_table_table .ant-table-placeholder {
    background: transparent !important;
}

.cooperation__body_table_table .ant-table-placeholder:hover {
    background: rgba(255, 79, 79, 0.2) !important;
}

/*table style*/

.cooperation__body__table_pagination {
    display: flex;
    align-items: center;
}

/*pagination*/
.cooperation__body__table_pagination .ant-pagination-item a {
    font-family: Inter;
    font-weight: 700;
    font-size: 12px;
    color: rgba(255, 79, 79, 1) !important;
}

.cooperation__body__table_pagination .ant-pagination-item-link {
    color: rgba(255, 79, 79, 1) !important;
}

.cooperation__body__table_pagination .ant-pagination-item-ellipsis {
    color: rgba(255, 79, 79, 1) !important;
}

.cooperation__body__table_pagination .ant-pagination-item-link-icon {
    color: rgba(255, 79, 79, 1) !important;
}

.cooperation__body__table_pagination .ant-pagination-item-active {
    border-color: rgba(255, 79, 79, 1) !important;
    background: transparent !important;
}

.cooperation__body__table_pagination .ant-pagination-options {
    background: transparent !important;
    color: rgba(255, 79, 79, 1) !important;
}

/*pagination*/

.cooperation__body_balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
}

.cooperation__body_balance_inner {
    display: flex;
    align-items: center;
}

.cooperation__body_balance_inner h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    /*line-height: 24.2px;*/
    color: #FFFFFF;
    margin-right: 10px;
}

.cooperation__body_balance_inner_button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    padding: 5px 15px;
    cursor: pointer;
}

.cooperation__body_balance_inner_button_2 {
    background: transparent;
    border: 1px solid white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    padding: 5px 15px;
    cursor: pointer;
}

.cooperation_body_table_text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
}

/*aplication*/
.application_modal {
    background: rgba(23, 23, 23, 1);
    width: 350px;
    height: auto;
    padding: 10px 20px;
}

.application_modal_header h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
}

.application_modal_body_inner_inner {
    /*display : flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
    /*border-bottom: 1px solid rgba(255, 79, 79, 0.2);*/
    padding: 10px 0;
    word-wrap: break-word;
    white-space: normal;
    overflow-wrap: break-word;
}

.application_modal_body_inner_inner p:first-child {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: gray;
}

.application_modal_body_inner_inner p span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    color: gray;
}

.application_modal_body_inner_inner p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
}

.application_modal_body_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.application_modal_body_button button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    height: 35px;
    color: #FFFFFF;
    border: 0;
    outline: none;
    border-radius: 10px;
    padding: 0px 15px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 5px 0;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
}

.withDraw_modal {
    background: rgba(23, 23, 23, 1);
    width: 350px;
    height: auto;
    padding: 10px 20px;
}

.withDraw_modal_header h2 {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: center;
    color: #FFFFFF;
}

.withDraw_modal_body_field {
    margin-bottom: 10px;
}

.withDraw_modal_body_field p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 29.05px;
    color: #FFFFFF;
}

.library_body_modal_inner_addModal_inner_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.library_body_modal_inner_addModal_inner_buttons button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    height: 35px;
    width : 110px;
    color: #FFFFFF;
    border: 0;
    outline: none;
    border-radius: 10px;
    /*padding: 0px 15px;*/
    box-sizing: border-box;
    cursor: pointer;
    margin: 5px;
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
}
.library_body_modal_inner_addModal_inner_buttons button:first-child {
    border: 1px solid white !important;
    background: transparent!important;
}


.withDraw_modal .ant-form-item-required{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    /*line-height: 29.05px;*/
    text-align: center;
    color: #FFFFFF!important;
}
.withDraw_modal .ant-input {
    border-color: white;
    /*background: rgba(23, 23, 23, 1);*/
    background: transparent;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    color: white;
    height: 35px;
    /*line-height: 24.2px;*/
}

.withDraw_modal .ant-input::placeholder {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: gray;
    /*line-height: 24.2px;*/
}

.withDraw_modal .ant-input:hover {
    border-color: white;
}

/*class="ant-input ant-input-status-error css-dev-only-do-not-override-gzal6t css-dev-only-do-not-override-gzal6t"*/
.withDraw_modal .ant-input-status-error .ant-input {
    border-color: #ff4d4f !important;
}

.withDraw_modal input::-webkit-inner-spin-button {
    display: none;
}

.withDraw_modal .ant-form-item-explain-error {
    font-family: Inter;
}

.withDraw_modal .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: #ff4d4f;
}

@media (max-width: 576px) {
    .cooperation {
        /*height: 100%;*/
        /*overflow-y: auto;*/
    }

    .cooperation_header {
        display: inline-block;
        height: auto;
    }

    .cooperation__body_balance_inner h2 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        /*line-height: 24.2px;*/
        color: #FFFFFF;
        margin-right: 10px;
    }

    .cooperation__body_balance_inner_button {
        font-size: 12px;
        padding: 5px;
    }

    .cooperation__body_balance_inner_button_2 {
        font-size: 12px;
        padding: 5px;
    }

}