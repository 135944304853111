.links {
    /*height: 100%!important;*/
    overflow-y: auto;
}

.links_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.links_header h2 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
}

.links_body {
    height: calc(100vh - 145px);
    border: 4px solid rgba(255, 79, 79, 0.2);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    overflow-y: auto;
}

.links_body_inner {
    width: 100%;
    height: 100%;
    padding: 30px 50px;
}

.links_body_inner:first-child {
    border-right: 4px solid rgba(255, 79, 79, 0.2);
}



.links_body .ant-collapse {
    border: 2px solid rgba(255, 79, 79, 0.2) !important;
    border-radius: 15px !important;
    margin-bottom: 20px!important;
}

.links_body .ant-collapse-item {
    border: 0 !important;
}
.links_body .ant-collapse-header-text{
    font-family: Inter;
    font-size: 19px!important;
    font-weight: 700!important;
    line-height: 19.36px!important;
    text-align: left;
    color: #FFFFFF!important;
}
.links_body .ant-collapse-expand-icon{
    position: absolute!important;
    right: 0!important;
    color: rgba(255, 79, 79, 0.2) !important;
}
.links_body .ant-collapse-expand-icon .anticon{
    font-size: 17px!important;
}
.links_body .ant-collapse-content {
    background: transparent!important;
    font-family: Inter;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 12.1px;
    text-align: left;
    color: #FFFFFF !important;
    border: 0 !important;
    /*border-radius: 20px!important;*/
}
.links_body .ant-collapse-content-active {
    background: transparent !important;
    border-radius: 20px !important;
    border: 0 !important;
}
.links_body .ant-collapse-content-box{
    padding: 10px 30px!important;
}

.links_body .ant-collapse-content-box ul li {
    margin: 15px 0;
}


/*responsive*/

@media (max-width: 576px) {
    .links_header h2 {
        font-size: 14px;
    }
    .links_body {
        height: calc(100vh - 260px);
        /*height: 100%;*/
        /*overflow-y: auto;*/
        border: 4px solid rgba(255, 79, 79, 0.2);
        display: block;
        flex-wrap: wrap;
        /*grid-template-columns: 1fr 1fr;*/
        /*grid-gap: 0;*/
        overflow-y: auto;
    }
    .links_body_inner{
        padding : 10px;
        height: auto;
    }

    .links_body_inner:first-child {
        border-right: 0px solid rgba(255, 79, 79, 0.2);
    }

}
