.payment_modal {
    background-color: #171717;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 100px;
    border-radius: 15px;
    position: relative;
    width: 690px;
}
.payment_modal_cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.payment_modal_cancel path {
    color: rgba(255, 79, 79, 1);
    size: 30px;
    width: 23px;
    height: 23px;
}

.payment_modal_inner_title h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: white;
}

.payment_modal_inner_card_number {
    height: 40px;
    /*background-color: black;*/
    /*width: border-box;*/
    margin: 20px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.payment_modal_inner_card_number input{
    height: 100%;
    max-width: 203px;
    border-radius: 10px;
    background-color: black;
    border: none;
    outline: none;
    padding: 0 10px!important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    color : white;
}
.payment_modal_inner_validity_period_inner input{
    height: 100%;
    max-width: 75px;
    border-radius: 10px;
    background-color: black;
    border: none;
    outline: none;
    padding: 0 10px!important;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    color : white;
}
.payment_modal_inner_validity_period p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: white;
}

.payment_modal_inner_validity_period_inner {
    height: 40px;
    /*background-color: black;*/
    margin-top: 10px;
    border-radius: 10px;
}

.payment_modal_inner_payment {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.payment_modal_inner_payment p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    color: white;
}

.payment_modal_inner_payment p:last-child {
    font-style: italic;
    margin-left: 10px;
}

.payment_modal_inner_text {
    display: flex;
    align-items: center;
    margin: 15px 0;
}

.payment_modal_inner_text p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    margin-left: 10px;
    color: white;
}

.payment_modal_inner_button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment_modal_inner_button button {
    background: linear-gradient(90deg, #A50000 0%, #FF4F4F 100%);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    color: white;
    width: auto;
    padding: 10px 30px;
    border-radius: 40px;
    border: none;
    cursor: pointer;
}
.payment_modal .ant-form-item-control-input-content{
    height: 40px;
}
@media(max-width: 576px) {
    .payment_modal {
        padding: 20px 20px;
        border-radius: 15px;
        width: 350px;
    }
    .payment_modal_cancel path {
        color: rgba(255, 79, 79, 1);
    }

    .payment_modal_inner_title h2 {
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: center;
        color: white;
    }
    .payment_modal_inner_validity_period p {
        font-size: 10px;
    }

    .payment_modal_inner_payment p {
        font-size: 10px;
    }
    .payment_modal_inner_text p {
        font-size: 10px;
    }
    .payment_modal_inner_button button {
        font-size: 10px;
        width:auto;
        padding: 5px 20px;
    }
}
